import React from "react"
import classNames from "classnames"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from "../ImageMeta"

const HowToSteps = ({ content, steps, colorBack }) => {
  const mainClasses = classNames("body-section bc__steps", {
    "color-back": colorBack
  })

  return (
    <div className={mainClasses}>
      <div className="columns">
        <div className="column is-5"></div>
        <div className="column">
          <MarkdownViewer className="has-text-centered" markdown={content} />
        </div>
        <div className="column is-5"></div>
      </div>
      <div className="bc__steps-cards">
        {steps.map((item, i) => (
          <div key={i} className="bc__steps-card">
            <div className="card-number">{i + 1}</div>
            <ImageMeta cloudname="nuvolum" publicId={item.icon} width="auto" />
            <MarkdownViewer markdown={item.blurb} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default HowToSteps
