import React from "react"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ButtonGroupMap from "../Button/ButtonGroupMap"

const BGImageTextSection = ({ bgImg, content, buttons }) => {
  return (
    <div className="bc__bg-text-section">
      <div className="columns">
        <div className="column is-5"></div>
        <div className="column">
          <MarkdownViewer markdown={content} />
          {/* <ButtonGroupMap isCentered buttons={buttons} /> */}
        </div>
        <div className="column is-5"></div>
      </div>
    </div>
  )
}

export default BGImageTextSection
