import React from "react"
import ImageMeta from "../ImageMeta"

const CardCollections = ({ collections }) => {
  return (
    <div className="body-section columns">
      <div className="column is-3"></div>
      <div className="column bc__card-collection">
        {collections.map(col => (
          <ImageMeta cloudname="nuvolum" publicId={col.image} width="auto" />
        ))}
      </div>
      <div className="column is-3"></div>
    </div>
  )
}

export default CardCollections
