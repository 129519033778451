import React from "react"
import classNames from "classnames"
import ImageMeta from "../ImageMeta"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const Benefits = ({ colorBack, content, benefits }) => {
  const mainClasses = classNames("body-section bc__benefits", {
    "color-back": colorBack
  })
  return (
    <div className={mainClasses}>
      <div className="columns">
        <div className="column is-5"></div>
        <div className="column">
          <MarkdownViewer markdown={content} />

          <div className="benefits-grid">
            {benefits.map((item, i) => (
              <div key={i} className="benefits-grid__item">
                <ImageMeta
                  cloudname="nuvolum"
                  publicId={item.icon}
                  width="auto"
                />
                <MarkdownViewer markdown={item.blurb} />
              </div>
            ))}
          </div>

          <ImageMeta
            publicId="BROM/DEV/wlds-smile-with-style-tagline"
            width="auto"
            className="wlds-tagline-img"
            cloudname="nuvolum"
          />
        </div>
        <div className="column is-5"></div>
      </div>
    </div>
  )
}

export default Benefits
