import React from "react"
import ImageMeta from "../ImageMeta"

const ImageStrip = ({ images }) => {
  return (
    <div className="bc__image-strip">
      {images.map(img => (
        <ImageMeta key={img} cloudname="nuvolum" publicId={img} width="auto" />
      ))}
    </div>
  )
}

export default ImageStrip
